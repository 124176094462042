<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Tarifas</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Hidrocarburos">Hidrocarburos</router-link>
                  </li>
                  <li class="breadcrumb-item active">Administración</li>
                  <li class="breadcrumb-item active">Gestión contratos</li>
                  <li class="breadcrumb-item active">Tarifas</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <h5 class="mb-0">Formulario {{ accion }} Tarifas</h5>
              </div>
              <div class="card-body">
                <div class="row">
                  <!---- Campo nombre de la tarifa ---->
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="nombre">Nombre</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="nombre"
                        v-model="form.nombre"
                        :disabled="
                          !$store.getters.can('hidrocarburos.tarifas.edit')
                        "
                        :class="
                          $v.form.nombre.$invalid ? 'is-invalid' : 'is-valid'
                        "
                      />
                    </div>
                  </div>
                  <!---- Select Producto líquido ---->
                  <div
                    class="form-group col-md-4"
                    v-if="this.form.tipo_operacion == 1"
                  >
                    <label for="">Producto liquido</label>
                    <v-select
                      :class="[
                        $v.form.producto_liquido_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      v-model="form.producto_liquido_id"
                      placeholder="Productos líquidos"
                      label="nombre"
                      class="form-control form-control-sm p-0"
                      :options="productos"
                      :disabled="
                        !$store.getters.can('hidrocarburos.tarifas.edit')
                      "
                    >
                    </v-select>
                  </div>
                  <!---- Select Tipo de vehículo ---->
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for=""> Tipo de vehículo</label>
                      <v-select
                        :class="[
                          $v.form.tipo_vehiculo_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        v-model="form.tipo_vehiculo_id"
                        placeholder="Tipo de vehículo"
                        label="nombre"
                        class="form-control form-control-sm p-0"
                        :options="tipo_vehi"
                        :filterable="false"
                        @search="buscarTiposvehi"
                        :disabled="
                          !$store.getters.can('hidrocarburos.tarifas.edit')
                        "
                      >
                      </v-select>
                    </div>
                  </div>
                  <!---- Select Ruta ---->
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="name">Ruta</label>
                      <v-select
                        :class="[
                          $v.form.ruta_id.$invalid ? 'is-invalid' : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        v-model="form.ruta_id"
                        placeholder="Ruta"
                        label="nombre"
                        class="form-control form-control-sm p-0"
                        :options="ruta"
                        :filterable="false"
                        @search="buscarRuta"
                        :disabled="
                          !$store.getters.can('hidrocarburos.tarifas.edit')
                        "
                      >
                      </v-select>
                    </div>
                  </div>
                  <!--- Inicio Select Categoria -------->
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="categoria">Categoria</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="form.categoria"
                        label="categoria"
                        :class="
                          $v.form.categoria.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        :disabled="
                          !$store.getters.can('hidrocarburos.tarifas.edit')
                        "
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="categoria in listasForms.categorias"
                          :key="categoria.numeracion"
                          :value="categoria.numeracion"
                        >
                          {{ categoria.descripcion }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <!-- campo kilometraje -->
                  <div class="col-md-2">
                    <label for="fecha_expedicion">Kilometraje: </label>
                    <input
                      type="number"
                      step="any"
                      class="form-control form-control-sm"
                      id="km"
                      v-model="form.km"
                      :class="$v.form.km.$invalid ? 'is-invalid' : 'is-valid'"
                      :disabled="
                        !$store.getters.can('hidrocarburos.tarifas.edit')
                      "
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label for="tipo_operacion">Tipo de Operacion</label>
                    <select
                      class="form-control form-control-sm"
                      id="tipo_operacion"
                      v-model="form.tipo_operacion"
                      :class="
                        $v.form.tipo_operacion.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      :disabled="
                        !$store.getters.can('hidrocarburos.tarifas.edit')
                      "
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="operaciones in listasForms.tipo_operaciones"
                        :key="operaciones.numeracion"
                        :value="operaciones.numeracion"
                      >
                        {{ operaciones.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <TarifasDetalles v-if="form.id"></TarifasDetalles>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="!$v.form.$invalid"
                    @click="save()"
                    v-if="
                      $store.getters.can('hidrocarburos.tarifas.create') ||
                        $store.getters.can('hidrocarburos.tarifas.edit')
                    "
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, minLength, email } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";
import TarifasDetalles from "./TarifasDetalles";

export default {
  name: "TarifasForm",
  components: {
    Loading,
    vSelect,
    TarifasDetalles,
  },
  data() {
    return {
      form: {
        id: null,
        nombre: null,
        producto_liquido_id: null,
        tipo_vehiculo_id: null,
        ruta_id: null,
        tarifa: null,
        tiempo_cargue_sb: null,
        tiempo_descargue_sb: null,
        fecha_ingreso: null,
        fecha_inicio_vigencia: null,
        fecha_fin_vigencia: null,
        radicado: null,
        categoria: null,
        km: null,
        tipo_operacion: null,
      },
      error: "",
      accion: "",
      metodo: "",
      listasForms: {
        categorias: [],
        tipo_operaciones: [],
      },
      productos: [],
      tipo_vehi: [],
      ruta: [],
      id: this.$route.params.id,
      cargando: false,
    };
  },
  validations() {
    if (this.form.tipo_operacion == 1) {
      return {
        form: {
          nombre: {
            required,
          },

          tipo_vehiculo_id: {
            required,
          },
          ruta_id: {
            required,
          },
          producto_liquido_id: {
            required,
          },
          categoria: {
            required,
          },
          km: {
            required,
          },
          tipo_operacion: {
            required,
          },
        },
      };
    } else {
      return {
        form: {
          nombre: {
            required,
          },

          tipo_vehiculo_id: {
            required,
          },
          ruta_id: {
            required,
          },

          categoria: {
            required,
          },
          km: {
            required,
          },
          tipo_operacion: {
            required,
          },
        },
      };
    }
  },
  methods: {
    buscarProductosliquidos() {
      let me = this;
      var url = "api/hidrocarburos/Productos/lista";
      axios
        .get(url)
        .then(function(response) {
          let respuesta = response.data;
          let lista = [];
          for (let i = 0; i < respuesta.length; i++) {
            lista.push({
              id: respuesta[i].id,
              nombre: respuesta[i].nombre + " - " + respuesta[i].sitio.nombre,
              codigo: respuesta[i].codigo,
              api: respuesta[i].api,
              bsw: respuesta[i].bsw,
              azufe: respuesta[i].azufre,
              porc_incertidumbre_exp: respuesta[i].porc_incertidumbre_exp,
              bls_incertidumbre_exp: respuesta[i].bls_incertidumbre_exp,
              sitio_id: respuesta[i].sitio_id,
            });
          }
          me.productos = lista;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarTiposvehi(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/tiposVehiculos/lista?nombre=" + search;
        axios
          .get(url, {
            params: {
              linea_negocio_id: 2,
            },
          })
          .then(function(response) {
            let respuesta = response.data;

            me.tipo_vehi = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarRuta(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/rutas/lista?nombre=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.ruta = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    getCategorias() {
      axios.get("/api/lista/72").then((response) => {
        this.listasForms.categorias = response.data;
      });
    },

    getTipoOperacion() {
      axios.get("/api/lista/83").then((response) => {
        this.listasForms.tipo_operaciones = response.data;
      });
    },

    save() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        let prod = null;
        if (this.form.tipo_operacion == 1) {
          prod = this.form.producto_liquido_id.id;
        }

        let data = {
          id: this.form.id,
          nombre: this.form.nombre,
          producto_liquido_id: prod,
          tipo_vehiculo_id: this.form.tipo_vehiculo_id.id,
          ruta_id: this.form.ruta_id.id,
          fecha_ingreso: this.form.fecha_ingreso,
          categoria: this.form.categoria,
          km: this.form.km,
          tipo_operacion: this.form.tipo_operacion,
        };

        axios({
          method: this.metodo,
          url: "/api/hidrocarburos/tarifas",
          data: data,
        })
          .then((res) => {
            this.cargando = false;
            if (res.data.status == 0) {
              this.$swal({
                icon: "error",
                title: res.data.msg,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            } else {
              return this.$router.push({
                name: "/Hidrocarburos/Tarifas",
                params: {
                  msg: "La tarifa se guardo exitosamente..!!",
                },
              });
            }
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title:
                "Ha ocurrido un error, por favor intente la acción nuevamente." +
                e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          });
      }
    },

    back() {
      return this.$router.replace("/Hidrocarburos/Tarifas");
    },

    init() {
      this.accion = this.$route.params.accion;
      if (this.$route.params.accion == "Editar") {
        let data = this.$route.params.data_edit;

        if (data.tipo_operacion == 1) {
          this.form = {
            id: data.id,
            nombre: data.nombre,
            producto_liquido_id: {
              id: data.producto_liquido.id,
              nombre: data.producto_liquido.nombre,
            },
            tipo_vehiculo_id: {
              id: data.tipo_vehiculo.id,
              nombre: data.tipo_vehiculo.nombre,
            },
            ruta_id: {
              id: data.ruta.id,
              nombre: data.ruta.nombre,
            },
            categoria: data.categoria,
            tipo_operacion: data.tipo_operacion,
            km: data.km,
            tarifa: data.tarifa,
            tiempo_cargue_sb: data.tiempo_cargue_sb,
            valor_hora_galon_tiempo_cargue: data.valor_hora_galon_tiempo_cargue,
            tiempo_descargue_sb: data.tiempo_descargue_sb,
            valor_hora_galon_tiempo_descargue:
              data.valor_hora_galon_tiempo_descargue,
            fecha_ingreso: data.fecha_ingreso,
            fecha_inicio_vigencia: data.fecha_inicio_vigencia,
            fecha_fin_vigencia: data.fecha_fin_vigencia,
            radicado: data.radicado,
          };
        } else {
          this.form = {
            id: data.id,
            nombre: data.nombre,
            tipo_vehiculo_id: {
              id: data.tipo_vehiculo.id,
              nombre: data.tipo_vehiculo.nombre,
            },
            ruta_id: {
              id: data.ruta.id,
              nombre: data.ruta.nombre,
            },
            categoria: data.categoria,
            tipo_operacion: data.tipo_operacion,
            km: data.km,
            tarifa: data.tarifa,
            tiempo_cargue_sb: data.tiempo_cargue_sb,
            valor_hora_galon_tiempo_cargue: data.valor_hora_galon_tiempo_cargue,
            tiempo_descargue_sb: data.tiempo_descargue_sb,
            valor_hora_galon_tiempo_descargue:
              data.valor_hora_galon_tiempo_descargue,
            fecha_ingreso: data.fecha_ingreso,
            fecha_inicio_vigencia: data.fecha_inicio_vigencia,
            fecha_fin_vigencia: data.fecha_fin_vigencia,
            radicado: data.radicado,
          };
        }

        this.metodo = "PUT";
      } else {
        this.metodo = "POST";
      }
    },
  },
  mounted() {
    this.init();
    this.getCategorias();
    this.getTipoOperacion();
    this.buscarProductosliquidos();
  },
};
</script>
